import React from 'react';
import { bool } from 'prop-types';
import CustomFilterPlain from './CustomFilterPlain';
import CustomFilterPopup from './CustomFilterPopup';

const CustomFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? <CustomFilterPopup {...rest} /> : <CustomFilterPlain {...rest} />;
};

CustomFilter.defaultProps = {
  showAsPopup: false,
};

CustomFilter.propTypes = {
  showAsPopup: bool,
};

export default CustomFilter;
